<template>
    <v-dialog
        v-model="dialog"
        max-width="700"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}}
                </v-card-title>
                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    <v-select
                        v-model="vals.edad"
                        rounded
                        filled
                        :items="itemsEscala.edad"
                        label="Edad"
                    ></v-select>
                    
                    <v-subheader>Valorar durante 15 segundos</v-subheader>

                    <v-select
                        v-model="vals.comportamiento"
                        rounded
                        filled
                        :items="itemsEscala.comportamiento"
                        label="Estado de conducta (Ojos y mov faciales)"
                    ></v-select>

                    <v-subheader>Valorar durante 30 segundos</v-subheader>
                    <v-row class="px-1" >
                        <v-col cols="6">
                            <v-select
                                v-model="vals.fc"
                                rounded
                                filled
                                :items="itemsEscala.fc"
                                label="Cambios en la FRECUENCIA CARDÍACA"
                            ></v-select>
                            
                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="vals.sat"
                                rounded
                                filled
                                :items="itemsEscala.sat"
                                label="Cambios en la SATURACIÓN DE OXÍGENO"
                            ></v-select>

                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="vals.cejas"
                                rounded
                                filled
                                :items="itemsEscala.cejas"
                                label="Cejas fruncidas"
                            ></v-select>

                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="vals.ojos"
                                rounded
                                filled
                                :items="itemsEscala.ojos"
                                label="Ojos apretados"
                            ></v-select>

                        </v-col>
                        <v-col cols="6">
                            <v-select
                                v-model="vals.labio"
                                rounded
                                filled
                                :items="itemsEscala.labio"
                                label="Surco nasolabial"
                            ></v-select>

                        </v-col>


                    </v-row>

                    <v-text-field
                        v-model="observaciones"
                        
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>


                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        color="success"
                        :loading="loading"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        loading:false,
        observaciones:'',
        vals:{
            edad:0,
            comportamiento:0,
            fc:0,
            sat:0,
            cejas:0,
            ojos:0,
            labio:0,
        },
        itemsEscala:{
            edad:[
                {value:0, text:'Mayor a 36 SDG'},
                {value:1, text:'Entre 36 y 32 SDG'},
                {value:2, text:'Entre 32 y 28 SDG'},
                {value:3, text:'Menor a 28 SDG'},
            ],
            
            comportamiento:[
                {value:0, text:'Despierto y activo'},
                {value:1, text:'Despierto e inactivo'},
                {value:2, text:'Dormido y activo'},
                {value:3, text:'Dormido e inactivo'},
            ],
            fc:[
                {value:0, text:'Aumento de 0 a 4 ppm'},
                {value:1, text:'Aumento de 5 a 14 ppm'},
                {value:2, text:'Aumento de 15 a 24 ppm'},
                {value:3, text:'Aumento mas de 25 ppm'},
            ],
            sat:[
                {value:0, text:'Disminución de 0 a 2.4 %'},
                {value:1, text:'Disminución de 2.5 a 4.9 %'},
                {value:2, text:'Disminución de 5 a 7.4 %'},
                {value:3, text:'Disminución mayor a 7.5 %'},
            ],
            cejas:[
                {value:0, text:'De 0 a 3 seg'},
                {value:1, text:'De 3 a 12 seg'},
                {value:2, text:'De 12 a 21 seg'},
                {value:3, text:'Mayor a 21 seg'},
            ],
            ojos:[
                {value:0, text:'De 0 a 3 seg'},
                {value:1, text:'De 3 a 12 seg'},
                {value:2, text:'De 12 a 21 seg'},
                {value:3, text:'Mayor a 21 seg'},
            ],
            labio:[
                {value:0, text:'De 0 a 3 seg'},
                {value:1, text:'De 3 a 12 seg'},
                {value:2, text:'De 12 a 21 seg'},
                {value:3, text:'Mayor a 21 seg'},
            ],
            

        },
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        }
    },
    methods:{
        cancela(){
            this.dialog = false
            this.observaciones = ''
            this.vals={
                edad:0,
                comportamiento:0,
                fc:0,
                sat:0,
                cejas:0,
                ojos:0,
                labio:0,
            }
        },
        async agrega(){
            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'dolorPipp',
                valor: JSON.stringify(this.vals),
                internamiento:this.internamiento,
                observaciones: this.observaciones
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.vals={
                        edad:0,
                        comportamiento:0,
                        fc:0,
                        sat:0,
                        cejas:0,
                        ojos:0,
                        labio:0,
                    }
                    this.observaciones = ''
                    this.loading = false
                    this.dialog = false

                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }
            
        },
    },
}
</script>

<style>

</style>